<script setup lang="ts">
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';
import { onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

import BaseBodyText from '@/components/base/typography/BaseBodyText.vue';
import UnstyledButton from '@/components/base/UnstyledButton.vue';
import ExpandableMenuLinks from '@/components/layout/header/mobile/ExpandableMenuLinks.vue';
import NavHeader from '@/components/layout/header/mobile/NavHeader.vue';
import { useRouterLinks } from '@/composables/navigation/useRouterLinks';
import { sendNavigationMenuEngagement } from '@/utils/analytics/navigation';
import { LargeMenu, mobileAttribution } from '@/utils/navMenu';

const emit = defineEmits(['close']);
const props = defineProps<{ defaultExpanded?: boolean; isOpen: boolean; menu: LargeMenu }>();

const rootElement = ref<HTMLElement>();

const { activate, deactivate } = useFocusTrap(rootElement, {
  allowOutsideClick: true,
  onDeactivate: () => emit('close'),
  setReturnFocus: (nodeFocusedBeforeActivation) => {
    const isSearchField =
      document.activeElement?.tagName === 'INPUT' &&
      document.activeElement?.getAttribute('type') === 'search';
    return isSearchField ? false : nodeFocusedBeforeActivation;
  },
});

const getLooseLinksTitle = (i: number) => (i === 0 ? 'Featured' : 'Other');

function reportClick(linkText: string) {
  sendNavigationMenuEngagement({
    link_text: linkText,
    menu_name: props.menu.header.text,
    source: 'Mobile Navigation',
  });
}

onMounted(() => {
  if (props.isOpen) setTimeout(activate, 100);

  watch(
    () => props.isOpen,
    async (val) => {
      if (val) {
        setTimeout(activate, 100);
      } else {
        deactivate();
      }
    },
  );
});

useRouterLinks(rootElement, useRouter());
</script>

<template>
  <div class="relative overflow-y-auto" ref="rootElement">
    <div
      class="fixed inset-x-0 top-0 z-10 py-0.5 flex items-center justify-center w-full bg-white border-b border-solid border-nuts-neutral-200"
    >
      <UnstyledButton
        class="absolute left-0 p-2 pl-4"
        data-promo="1"
        data-promo-creative="Mobile Menu Nav Links"
        data-promo-name="Back"
        @click="emit('close')"
      >
        <img
          alt="close subcategory menu"
          data-test="close-sub-menu"
          src="@/assets/back-button.svg"
          class="left-0 object-contain w-5 h-5"
        />
      </UnstyledButton>
      <NavHeader centered :header="menu.header" />
    </div>
    <div class="py-2 mt-11" data-test="nav-header">
      <template v-for="section in menu.sections" :key="section">
        <template v-for="({ group }, i) in section.linkGroups" :key="group">
          <ExpandableMenuLinks
            :defaultExpanded="defaultExpanded"
            :title="group.headerText || getLooseLinksTitle(i)"
          >
            <ul>
              <li v-for="{ link } in group.links" :key="link.url">
                <a
                  v-bind="
                    mobileAttribution(menu, group.headerText ?? getLooseLinksTitle(i), link.text)
                  "
                  class="flex w-full py-2 pl-8 pr-4 hover:bg-nuts-stone-100"
                  :href="link.url"
                  @click="reportClick(link.text)"
                >
                  <BaseBodyText class="text-black">{{ link.text }}</BaseBodyText>
                </a>
              </li>
            </ul>
          </ExpandableMenuLinks>
        </template>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
a {
  @apply no-underline;
}
</style>
