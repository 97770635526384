<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';
import { storeToRefs } from 'pinia';
import { computed, nextTick, onBeforeUnmount, onMounted, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import questionCircleBlack from '@/assets/icons/question-circle-black.svg';
import signOut from '@/assets/icons/sign-out.svg';
import CloseIcon from '@/components/base/assets/CloseIcon.vue';
import ReferIcon from '@/components/base/assets/ReferIcon.vue';
import UnstyledButton from '@/components/base/UnstyledButton.vue';
import AccountListItem from '@/components/layout/header/mobile/AccountListItem.vue';
import LargeMenu from '@/components/layout/header/mobile/LargeMenu.vue';
import NavHeader from '@/components/layout/header/mobile/NavHeader.vue';
import { useRouteChange } from '@/composables/navigation/useRouteChange';
import { useRouterLinks } from '@/composables/navigation/useRouterLinks';
import { useCheckout } from '@/composables/useCheckout';
import { useLoginModal } from '@/composables/useLoginModal';
import { useReferralElement } from '@/composables/useReferralElement';
import { useCustomer } from '@/stores/customer';
import { useOverlay } from '@/stores/overlay';
import { useSession } from '@/stores/session';
import {
  sendNavigationMenuEngagement,
  sendNavigationMenuImpression,
} from '@/utils/analytics/navigation';
import type { LargeMenu as LargeMenuModel, SiteNavigation } from '@/utils/navMenu';

defineProps<{ siteNavigation: SiteNavigation }>();
const emit = defineEmits(['handle-close']);

const mainNavRef = ref<HTMLElement>();
const referralLink = ref<HTMLElement>();

const router = useRouter();
const store = useStore();

const { cxMode } = useCheckout(store);
const { activate } = useFocusTrap(mainNavRef, {
  allowOutsideClick: true,
  onDeactivate: () => emit('handle-close'),
  setReturnFocus: (nodeFocusedBeforeActivation) => {
    const isSearchField =
      document.activeElement?.tagName === 'INPUT' &&
      document.activeElement?.getAttribute('type') === 'search';
    return isSearchField ? false : nodeFocusedBeforeActivation;
  },
});
const { handleOpen } = useLoginModal();
const overlay = useOverlay();
const { navigateTo } = useRouteChange(router);
const { userFirstName } = storeToRefs(useSession());

const subMenu = reactive({
  menu: undefined as LargeMenuModel | undefined,
  open: false,
});

const isMobile = useBreakpoints(breakpointsTailwind).smaller('md');
const isSignedIn = computed(() => !!useCustomer().customer || cxMode.value);

const handleClose = () => {
  emit('handle-close');
};

const onLinkClicked = (linkText: string) => {
  sendNavigationMenuEngagement({
    link_text: linkText,
    menu_name: 'Main',
    source: 'Mobile Navigation',
  });
};

const requireUser = (destination: string, text: string) => {
  if (destination) onLinkClicked(text);

  if (!destination?.match(/\/account|refer/) || isSignedIn.value) {
    if (!destination) return;
    navigateTo(destination);
  } else {
    handleOpen({ destination });
  }
  handleClose();
};

const openSubMenu = async (menu: LargeMenuModel) => {
  subMenu.menu = menu;
  subMenu.open = true;

  sendNavigationMenuImpression({ menu_name: menu.header.text, source: 'Mobile Navigation' });
};

const closeSubMenu = () => {
  subMenu.open = false;
};

onMounted(async () => {
  if (isMobile.value) window.Kustomer?.stop();
  overlay.show();
  await nextTick();

  activate();

  sendNavigationMenuImpression({ menu_name: 'Main', source: 'Mobile Navigation' });
});

onBeforeUnmount(() => {
  window.Kustomer?.start();
  overlay.hide();
});

const { showReferralElement } = useReferralElement(referralLink, 'mobile_menu_redesignv1');
useRouterLinks(mainNavRef, router);
</script>

<template>
  <div
    aria-label="Categories menu"
    data-test="mobile-menu-nav"
    aria-modal="true"
    class="fixed inset-0"
    role="dialog"
    @click.self="handleClose"
  >
    <nav
      aria-label="main"
      class="relative w-full h-full overflow-x-hidden bg-white md:shadow md:max-w-sm"
      ref="mainNavRef"
    >
      <div
        class="absolute flex flex-col w-full h-full overflow-auto transition-all duration-300 ease-in-out right-full md:right-96"
        :class="{ 'translate-x-full md:translate-x-96': !subMenu.open }"
      >
        <UnstyledButton
          class="sr-only"
          data-promo="1"
          data-promo-creative="Mobile Menu Nav Links"
          data-promo-name="Close"
          @click="handleClose"
        >
          <CloseIcon aria-label="close menu" data-test="close-mobile-menu" />
        </UnstyledButton>
        <div class="w-full">
          <ul class="py-2">
            <li
              v-for="{ menu } in siteNavigation.categories"
              :key="menu.header.url || menu.header.text"
            >
              <a
                v-if="menu.header.url"
                :href="menu.header.url"
                @click="onLinkClicked(menu.header.text)"
              >
                <NavHeader class="pl-4 hover:bg-nuts-stone-100" :header="menu.header" />
              </a>
              <UnstyledButton
                v-else
                class="flex items-center w-full hover:bg-nuts-stone-100"
                data-test="mobile-menu-nav-link"
                @click="openSubMenu(menu)"
              >
                <NavHeader class="pl-4" :header="menu.header" opensSubMenu />
              </UnstyledButton>
            </li>
          </ul>
          <hr class="h-px my-0 bg-neutral-300" />
          <ul class="py-2">
            <li v-for="{ icon, link } in siteNavigation.accountLinks" :key="link.url">
              <a
                data-promo="1"
                data-promo-creative="Mobile Menu Nav Links"
                :data-promo-name="`Account > Link: ${link.text}`"
                :href="link.url"
                @click.prevent="requireUser(link.url, link.text)"
              >
                <AccountListItem class="hover:bg-nuts-stone-100" :icon :text="link.text" />
              </a>
            </li>
            <li v-if="showReferralElement">
              <a v-once href="/refer" ref="referralLink" @click="onLinkClicked('Refer and Earn')">
                <AccountListItem
                  class="hover:bg-nuts-stone-100"
                  text="Refer and Earn"
                  data-test="refer-and-earn-mobile"
                >
                  <ReferIcon class="text-black" :size="24" />
                </AccountListItem>
              </a>
            </li>
          </ul>
          <hr class="h-px my-0 bg-neutral-300" />
          <ul class="py-2">
            <li>
              <a href="https://help.nuts.com" @click="onLinkClicked('Help')">
                <AccountListItem
                  class="hover:bg-nuts-stone-100"
                  :icon="questionCircleBlack"
                  text="Help"
                />
              </a>
            </li>

            <li v-if="userFirstName">
              <a href="/sign/out" class="g_id_signout" @click="onLinkClicked('Sign Out')">
                <AccountListItem class="hover:bg-nuts-stone-100" :icon="signOut" text="Sign Out" />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div
        class="absolute flex flex-col w-full h-full overflow-auto transition-all duration-300 ease-in-out left-full md:left-96"
        :class="{
          '-translate-x-full md:-translate-x-96': subMenu.open,
          'animate-invisible': !subMenu.open,
        }"
      >
        <LargeMenu
          v-if="subMenu.menu"
          defaultExpanded
          :isOpen="subMenu.open"
          :menu="subMenu.menu"
          @close="closeSubMenu"
        />
      </div>
    </nav>
  </div>
</template>

<style lang="scss" scoped>
a {
  @apply no-underline;
}

.animate-invisible {
  animation: delayed-visibility-change 0.3s ease-in-out normal forwards;
}

@keyframes delayed-visibility-change {
  99% {
    visibility: visible;
  }
  100% {
    visibility: hidden;
  }
}
</style>
